import "./customers.scss";
import { React, useEffect, useState } from 'react';
import checkRole from '../../checkRole';
import { useNavigate, useLocation } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import CustomTable from "../../components/CustomTable/CustomTable";
import { mainAxios } from "../../axiosConfig";
import { getAvatar, getName, renameKey, sortTable, filterTable, isValidValue, setupName } from "../../Utils";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { ReactComponent as WarningIcon } from '../../assets/ic_warning.svg';
import { ReactComponent as WarningWhiteIcon } from '../../assets/ic_warning_white.svg';
import { ReactComponent as ShieldIcon } from '../../assets/ic_shield.svg';
import { ReactComponent as ShieldWhiteIcon } from '../../assets/ic_shield_white.svg';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from '../../components/SearchBar/SearchBar';

const Customers = (params) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialog, setDialog] = useState({
    showDialog: false,
    isDisable: false,
    selectedRow: {},
    message: ""
  });
  const [responseData, setResponseData] = useState({
    loading: true,
    data: []
  });

  const [sorting, setSorting] = useState({
    column: "",
    assending: true
  });
  const [filterValue, setFilterValue] = useState({
    searchValue: "",
    filterValue: []
  });
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    if (!responseData.loading) {
      setResponseData({
        loading: true,
        data: []
      });
    }
    getUserListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const customerAgentColumns = [
    { label: "Name", accessor: "name", isSort: true, width: '25%', type: "string", isFilter: true },
    { label: "Email", accessor: "login_id", isSort: true, width: '31%', type: "string", isFilter: true },
    { label: "Mobile No.", accessor: "mobile_no", isSort: true, width: '17%', type: "string", isFilter: true },
    { label: "Last Login", accessor: "last_login", isSort: true, width: '17%', type: "date", sortingKey: 'last_login_at', isFilter: true },
    { label: "Action", accessor: "action", isSort: false }
  ]

  // 1015
  const getUserListAPI = async () => {
    try {
      const res = await mainAxios.get('customers', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        var userList = res.response.data.customers;
        userList.forEach(obj => renameKey(obj, "customer_id", "id"))
        const currentDate = new Date();
        for (let i = 0; i < userList.length; i++) {
          let lastLoginAt = userList[i].last_login_at;
          if (isValidValue(lastLoginAt)) {
            const lastLoginAtDate = new Date(lastLoginAt);
            const timeDifference = Math.abs(lastLoginAtDate - currentDate);
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const years = Math.round((timeDifference / 1000 / (60 * 60 * 24)) / 365.25);

            const lastLogin = seconds > 59 ? minutes > 59 ? hours > 23 ? days > 364 ? years + (years > 1 ? " year ago" : " years ago") : days + (days > 1 ? " days ago" : " days ago") : hours + (hours > 1 ? " hours ago" : " hour ago") : minutes + (minutes > 1 ? " minutes ago" : " minute ago") : seconds + (seconds > 1 ? " seconds ago" : " second ago")
            userList[i].last_login = lastLogin;
          }

          let deactivateAt = userList[i].deactivate_at;
          // if (deactivateAt != null) {
          //   const deactivateAtDate = new Date(deactivateAt);
          //   userList[i].isDisable = deactivateAtDate <= currentDate;
          // } else {
          //   userList[i].isDisable = false
          // }
          userList[i].isDisable = deactivateAt;
          var mobileNo = "";
          if (isValidValue(userList[i].country_code)) {
            mobileNo = userList[i].country_code + " ";
          }

          if (isValidValue(userList[i].phone_number)) {
            mobileNo = mobileNo + userList[i].phone_number;
          }

          userList[i].mobile_no = mobileNo;
          userList[i].name = setupName(userList[i].first_name, userList[i].last_name);
        }
        setResponseData({
          loading: false,
          data: userList
        });

        filter(filterValue, userList);
      } else {
        setResponseData({
          loading: false,
          data: {}
        });

        filter(filterValue, []);
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: {}
      });

      filter(filterValue, []);
    }
  }

  // 3017
  const reactivateUserAPI = async (id) => {
    try {
      const res = await mainAxios.put('customer_reactivate/' + id, {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        getUserListAPI();
      } else {
        setResponseData({
          ...responseData,
          loading: false,
        });
      }
    } catch (error) {
      setResponseData({
        ...responseData,
        loading: false,
      });
    }
  }

  // 4016
  const deactivateUserAPI = async (id) => {
    try {
      const res = await mainAxios.delete('customer_deactivate/' + id, {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        getUserListAPI();
      } else {
        setResponseData({
          ...responseData,
          loading: false,
        });
      }
    } catch (error) {
      setResponseData({
        ...responseData,
        loading: false,
      });
    }
  }

  function onClickRow(id) {
    users(id, 'view');
  }

  function users(id, action) {
    for (var data of responseData.data) {
      if (data.id === id) {
        navigate(`/customers/details?id=` + id);
        break;
      }
    }
  }

  function onClickStatus(id) {
    for (var data of responseData.data) {
      if (data.id === id) {
        setDialog({
          showDialog: true,
          isDisable: true,
          selectedRow: data
        })
      }
    }
  }

  function confirmStatus(id, isDisable) {
    setDialog({ showDialog: false });
    if (isDisable) {
      reactivateUserAPI(id);
    } else {
      deactivateUserAPI(id);
    }
  }

  function createDialog() {
    return dialog.showDialog ? dialog.isDisable ?
      (
        <ConfirmDialog
          id={dialog.selectedRow.id}
          title={dialog.selectedRow.isDisable ? 'Enable User' : 'Disable User'}
          subtitle={dialog.selectedRow.isDisable ? 'Enabling the account will allow the user to sign in or use the services of RiceBowl Xpress SG, continue?' : 'Disabling the account will not allow the user to sign in or use the services of RiceBowl Xpress SG, continue?'}
          iconTitle={dialog.selectedRow.isDisable ? <ShieldIcon /> : <WarningIcon />}
          backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
          button1Callback={(id) => setDialog({ showDialog: false })}
          button2Icon={dialog.selectedRow.isDisable ? <ShieldWhiteIcon /> : <WarningWhiteIcon />}
          button2Title={dialog.selectedRow.isDisable ? 'Enable' : 'Disable'}
          button2Style={{ backgroundColor: dialog.selectedRow.isDisable ? 'rgba(0, 137, 38, 1)' : '#FC7921', color: 'white' }}
          button2Callback={(id) => confirmStatus(id, dialog.selectedRow.isDisable)}
        />
      ) :
      (
        <ConfirmDialog
          iconTitle={''}
          title={''}
          subtitle={dialog.message}
          subTitleStyle={{ fontSize: 16 }}
          backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
          button1Style={{ visibility: "hidden" }}
          button2Icon={''}
          button2Title={'OK'}
          button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
          button2Callback={(id) => setDialog({ showDialog: false })}
        />
      )
      : null
  }

  function onSidebarClick() {
    setResponseData({
      loading: true,
      data: {}
    });
    getUserListAPI();
  }

  function onClickColumn(column, arr) {
    var columns = customerAgentColumns;
    var data = sortTable(column, columns, arr ?? filterList);
    column.data = data;
    setSorting(column);
  }

  function filter(filter, arr) {
    var list = filterTable(filter, arr ?? responseData.data);
    setFilterList(list);
    onClickColumn(sorting, list)
  }

  function handleSearchChange(value) {
    setFilterValue(value)
    filter(value);
  }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""} `}>
        <Sidebar page={"customers"} onSidebarClick={onSidebarClick} navChild={{ accounts: true }} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className="customers">
            <div className="header">
              <p>{`LIST OF CUSTOMERS`}</p>
              <SearchBar
                handleSearchChange={(value) => handleSearchChange(value)}
                value={filterValue}
                filterList={customerAgentColumns}
              />
            </div>
            <div className="table-container">
              <CustomTable
                columns={customerAgentColumns}
                tableData={sorting.data ?? filterList}
                numPerPage={10}
                isEdit={false}
                isDelete={false}
                isMore={false}
                isDisable={true}
                isDisableOnClickRow={false}
                onClickRow={(id) => onClickRow(id)}
                onClickStatus={(id) => onClickStatus(id)}
                sorting={sorting}
                onClickColumn={(column) => onClickColumn(column)}
              />
            </div>
            {createDialog()}
          </div>
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

export default checkRole(Customers);