import React, { useEffect, useState } from 'react'
import './addBooking.scss'
import { ReactComponent as TickIcon } from '../../assets/ic_tick.svg';
import { ReactComponent as RadioCheckedIcon } from '../../assets/ic_radio_checked.svg'
import { ReactComponent as RadioUncheckedIcon } from '../../assets/ic_radio_unchecked.svg'
import { useNavigate } from 'react-router-dom';
import checkRole from '../../checkRole';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../axiosConfig';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { WeekDaysOption, dateToString, getAvatar, getName, isValidValue, setupName } from '../../Utils';
import { CustomSelect } from '../../components/SelectOption/CustomSelect';

const AddBooking = (params) => {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        showDialog: false,
        isPayment: false,
        array: [],
        message: "",
        title: ""
    });
    const [responseData, setResponseData] = useState({
        loading: true
    });
    const [customer, setCustomer] = useState({});
    const [customers, setCustomers] = useState([]);
    const [service, setService] = useState({});
    const [services, setServices] = useState([]);
    const [designation, setDesignation] = useState({});
    const [designations, setDesignations] = useState([]);
    const [addressLabel, setAddressLabel] = useState({});
    const [addressLabels, setAddressLabels] = useState([]);
    const [address, setAddress] = useState('');
    const [addressNote, setAddressNote] = useState('');
    const [urgent, setUrgent] = useState(false);
    const [urgentFee, setUrgentFee] = useState('');
    const [bookingPackage, setBookingPackage] = useState({});
    const [bookingPackages, setBookingPackages] = useState([]);
    const [recurring, setRecurring] = useState(true);
    const [scheduleWeekday, setScheduleWeekday] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [time, setTime] = useState({});
    const [duration, setDuration] = useState({});

    useEffect(() => {
        getUserListAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 1015
    const getUserListAPI = async () => {
        try {
            const res = await mainAxios.get('customers', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var userList = res.response.data.customers;
                var dropdownArray = [];
                for (let i = 0; i < userList.length; i++) {
                    dropdownArray.push({ label: setupName(userList[i].first_name, userList[i].last_name), value: userList[i].customer_id });
                }
                setCustomers(dropdownArray);
                getServicesListAPI();
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 1017
    const getUserDetails = async (customerId) => {
        setResponseData({
            ...responseData,
            loading: true,
        });
        try {
            const res = await mainAxios.get('customer/' + customerId, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var customerAddresses = res.response.data.customer.customer_addresses;
                if (isValidValue(customerAddresses)) {
                    var dropdownArray = [];
                    for (let i = 0; i < customerAddresses.length; i++) {
                        dropdownArray.push({
                            label: customerAddresses[i].address_label,
                            value: customerAddresses[i].address_label,
                            address: customerAddresses[i].address,
                            address_note: customerAddresses[i].address_note,
                            address_timezone_offset: customerAddresses[i].address_timezone_offset,
                            address_building: customerAddresses[i].address_building,
                            address_unit: customerAddresses[i].address_unit
                        });
                    }
                    setAddressLabels(dropdownArray);
                }
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 1020
    const getServicesListAPI = async () => {
        try {
            const res = await mainAxios.get('services', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var servicesList = res.response.data.services;
                var dropdownArray = [];
                for (let i = 0; i < servicesList.length; i++) {
                    dropdownArray.push({ label: servicesList[i].title, value: servicesList[i].service_id });
                }
                setServices(dropdownArray);
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 1021
    const getServiceDetails = async (serviceId) => {
        setResponseData({
            ...responseData,
            loading: true,
        });
        try {
            const res = await mainAxios.get('service/' + serviceId, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                var designations = res.response.data.service.designations;
                if (isValidValue(designations)) {
                    var designationsArray = designations.split(',');
                    var dropdownArray = [];
                    for (let i = 0; i < designationsArray.length; i++) {
                        dropdownArray.push({ label: designationsArray[i], value: designationsArray[i] });
                    }
                    setDesignations(dropdownArray);
                }

                var bookingPackageOptions = res.response.data.booking_package_options;
                if (isValidValue(bookingPackageOptions)) {
                    var optionsDropdownArray = [];
                    for (let i = 0; i < bookingPackageOptions.length; i++) {
                        optionsDropdownArray.push({ label: bookingPackageOptions[i].label + " $ " + bookingPackageOptions[i].value.toFixed(2), value: bookingPackageOptions[i].value, package_label: bookingPackageOptions[i].label });
                    }
                    if (optionsDropdownArray.length > 0) {
                        setBookingPackage(optionsDropdownArray[0]);
                    }
                    setBookingPackages(optionsDropdownArray);
                }

                if (isValidValue(res.response.data.booking_urgent_fee)) {
                    setUrgentFee(res.response.data.booking_urgent_fee);
                }
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    const handleSave = (event) => {
        event.preventDefault();

        if (customer.value !== undefined && service.value !== undefined &&
            designation.value !== undefined && addressLabel.value !== undefined &&
            (!recurring || scheduleWeekday.length > 0) && startDate.length > 0 && (!recurring || endDate.length > 0) &&
            time.value !== undefined && duration.value !== undefined && bookingPackage.value !== undefined) {
            setResponseData({
                ...responseData,
                loading: true,
            });
            submitNewBooking(true);
        } else {
            setDialog({ ...dialog, isPayment: false, showDialog: true, message: "Please complete all required fields." });
        }
    }

    // 2031
    const submitNewBooking = async (isPreBooking) => {
        try {
            const res = await mainAxios.post('booking', {
                customer_id: customer.value,
                service_id: service.value,
                designation: designation.value,
                address_label: addressLabel.value,
                address_timezone_offset: addressLabel.address_timezone_offset,
                address: isPreBooking ? null : address,
                address_building: addressLabel.address_building,
                address_unit: addressLabel.address_unit,
                address_note: addressLabel.address_note,
                is_recurring: recurring,
                schedule_weekdays: scheduleWeekday.sort((a, b) => a.value - b.value).map(item => item.value).join(''),
                schedule_start_date: dateToString(startDate, "YYYY-MM-DD"),
                schedule_end_date: recurring ? dateToString(endDate, "YYYY-MM-DD") : dateToString(startDate, "YYYY-MM-DD"),
                schedule_time: time.value,
                schedule_duration: duration.value,
                package_label: bookingPackage.package_label,
                package_value: bookingPackage.value,
                is_urgent: urgent,
                use_credits: false
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });

                if (!isPreBooking) {
                    navigate("/bookings");
                } else {
                    var breakdown = res.response.data.booking.breakdown;
                    if (breakdown == null || breakdown.length === 0) {
                        setDialog({ ...dialog, isPayment: false, showDialog: true, message: "No valid sessions in the selected date range." });
                    } else {
                        breakdown.push({ key: "Total amount", value: "$ " + res.response.data.booking.amount.toFixed(2) })
                        setDialog({ showDialog: true, isPayment: true, title: "Payment Details", array: res.response.data.booking.breakdown });
                    }
                }
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function generateTimeSlots() {
        const timeSlots = [];
        const formatTime = (hour, minute) => {
            const period = hour < 12 ? 'AM' : 'PM';
            let formattedHour;

            if (hour === 0) {
                formattedHour = '00';
            } else if (hour === 12) {
                formattedHour = '12';
            } else {
                formattedHour = (hour % 12).toString().padStart(2, '0');
            }

            const formattedMinute = minute.toString().padStart(2, '0');
            return `${formattedHour}:${formattedMinute} ${period}`;
        };

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const label = formatTime(hour, minute);
                const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                timeSlots.push({ label, value });
            }
        }

        return timeSlots;
    }

    function generateHourSlots() {
        const hourSlots = [];
        for (let i = 3; i <= 10; i += 0.5) {
            const label = `${i} hours`;
            const value = `${i}`;
            hourSlots.push({ label, value });
        }
        return hourSlots;
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={dialog.title}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16, whiteSpace: 'pre-line' }}
                    backgroundButtonCallback={(id) => setDialog({ ...dialog, showDialog: false })}
                    button1Style={dialog.isPayment ? {} : { visibility: "hidden" }}
                    button1Callback={(id) => setDialog({ ...dialog, showDialog: false })}
                    button2Icon={''}
                    button2Title={dialog.isPayment ? 'Confirm' : 'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => dialog.isPayment ? submitNewBooking(false) : setDialog({ ...dialog, showDialog: false })}
                    isPayment={dialog.isPayment}
                    array={dialog.array}
                />
            )
            : null
    }

    function onSidebarClick() { }

    function selectCustomer(item) {
        setCustomer(item);
        setAddressLabel({});
        setAddressLabels([]);
        setAddress('');
        setAddressNote('');
        getUserDetails(item.value);
    }

    function selectService(item) {
        setService(item);
        setDesignation({});
        setDesignations([]);
        setBookingPackage({});
        setBookingPackages([]);
        getServiceDetails(item.value);
    }

    function selectAddressLabel(item) {
        setAddressLabel(item);
        setAddress(item.address);
        setAddressNote(item.address_note);
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="bookings" onSidebarClick={onSidebarClick} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="add-booking">
                        <form onSubmit={handleSave}>
                            <div className="header">
                                <div>
                                    <p>Add new booking</p>
                                    <p>Bookings &gt; Add new booking </p>
                                </div>
                                {/* <button onClick={() => navigate('/companies', { replace: true })}> */}
                                <button type='submit'>
                                    <span><TickIcon /></span>
                                    <span>Save</span>
                                </button>
                            </div>
                            <div className='content'>
                                <div className='details'>
                                    <div className='details-form'>
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Customer:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={customers} placeholder='Select Customer' required={true} isMulti={false} value={customer} onChangeValue={(e) => selectCustomer(e)} />
                                            </div>
                                        </div>
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Service:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={services} placeholder='Select Service' required={true} isMulti={false} value={service} onChangeValue={(e) => selectService(e)} />
                                            </div>
                                        </div>
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Designation:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={designations} placeholder='Select Designation' required={true} isMulti={false} value={designation} onChangeValue={(e) => setDesignation(e)} />
                                            </div>
                                        </div>
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Address Label:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={addressLabels} placeholder='Select Address Label' required={true} isMulti={false} value={addressLabel} onChangeValue={(e) => selectAddressLabel(e)} />
                                            </div>
                                        </div>
                                        {
                                            Object.keys(addressLabel).length !== 0 ?
                                                <div className='details-div'>
                                                    <label>Address:</label>
                                                    <div className='details-div-layout'>
                                                        <label className='details-div-layout-content'>{address}</label>
                                                    </div>
                                                </div> : undefined
                                        }
                                        {
                                            Object.keys(addressLabel).length !== 0 ?
                                                <div className='details-div'>
                                                    <label>Note:</label>
                                                    <div className='details-div-layout'>
                                                        <label className='details-div-layout-content'>{addressNote}</label>
                                                    </div>
                                                </div> : undefined
                                        }
                                        <div className='details-div'>
                                            <label className='required'>Urgent{isValidValue(urgentFee) ? " + $ " + urgentFee.toFixed(2) : ''}:</label>
                                            <div className='details-div-layout'>
                                                <div className='details-div-icon'>
                                                    <div className='details-div-radio' onClick={() => setUrgent(true)}>
                                                        {urgent ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                                                        <label className='details-div-radio-label'>Yes</label>
                                                    </div>
                                                    <div className='details-div-radio2' onClick={() => setUrgent(false)}>
                                                        {urgent ? <RadioUncheckedIcon /> : <RadioCheckedIcon />}
                                                        <label className='details-div-radio-label'>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Package:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={bookingPackages} placeholder='Select Package' required={true} isMulti={false} value={bookingPackage} onChangeValue={(e) => setBookingPackage(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <p className='details-title'>Schedule</p>
                                    <div className='details-form'>
                                        <div className='details-div'>
                                            <label className='required'>Recurring:</label>
                                            <div className='details-div-layout'>
                                                <div className='details-div-icon'>
                                                    <div className='details-div-radio' onClick={() => setRecurring(true)}>
                                                        {recurring ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                                                        <label className='details-div-radio-label'>Yes</label>
                                                    </div>
                                                    <div className='details-div-radio2' onClick={() => setRecurring(false)}>
                                                        {recurring ? <RadioUncheckedIcon /> : <RadioCheckedIcon />}
                                                        <label className='details-div-radio-label'>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            recurring ?
                                                <div className='details-div dropdown-div'>
                                                    <label className='required'>On:</label>
                                                    <div className='details-div-layout details-div-multi-layout'>
                                                        <CustomSelect list={WeekDaysOption()} placeholder='Select On' required={true} isMulti={true} value={scheduleWeekday} onChangeValue={(e) => setScheduleWeekday(e)} />
                                                    </div>
                                                </div> : undefined
                                        }
                                        <div className='details-div'>
                                            <label className='required'>Start Date:</label>
                                            <div className='details-div-layout'>
                                                <input className='details-div-layout-input' type='date' onChange={(e) => setStartDate(e.target.value)} value={startDate} required />
                                            </div>
                                        </div>
                                        {
                                            recurring ?
                                                <div className='details-div'>
                                                    <label className='required'>End Date:</label>
                                                    <div className='details-div-layout'>
                                                        <input className='details-div-layout-input' type='date' onChange={(e) => setEndDate(e.target.value)} value={endDate} required />
                                                    </div>
                                                </div> : undefined
                                        }
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Time:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={generateTimeSlots()} placeholder='Select Time' required={true} isMulti={false} value={time} onChangeValue={(e) => setTime(e)} />
                                            </div>
                                        </div>
                                        <div className='details-div dropdown-div'>
                                            <label className='required'>Duration:</label>
                                            <div className='details-div-layout details-div-multi-layout'>
                                                <CustomSelect list={generateHourSlots()} placeholder='Select Duration' required={true} isMulti={false} value={duration} onChangeValue={(e) => setDuration(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {createDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(AddBooking);