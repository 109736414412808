import React, { useEffect, useState } from 'react'
import './editAgentBanners.scss'
import { ReactComponent as TickIcon } from '../../../assets/ic_tick.svg';
import { useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../../axiosConfig';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { AgentBannerTypeOption, AgentEventOption, getAvatar, getName } from '../../../Utils';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AgentBannerItem } from './AgentBannerItem';

const EditAgentBanners = (params) => {
    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        showDialog: false,
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: false
    });

    const [agentBanners, setAgentBanners] = useState([{ id: 1, title: "", content: "", image_url: "", type: AgentBannerTypeOption().length > 0 ? AgentBannerTypeOption()[0].value : '', event: AgentEventOption().length > 0 ? AgentEventOption()[0].value : '' }]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const getAgentBannersPos = id => agentBanners.findIndex(agentBanner => agentBanner.id === id);

    const handleDragEnd = event => {
        const { active, over } = event

        if (active.id === over.id) return;

        setAgentBanners(agentBanners => {
            const originalPos = getAgentBannersPos(active.id);
            const newPos = getAgentBannersPos(over.id);

            return arrayMove(agentBanners, originalPos, newPos);
        })
    }

    useEffect(() => {
        if (params.action === "edit") {
            setResponseData({
                loading: true
            });
            getSetup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = (event) => {
        event.preventDefault();

        var newList = agentBanners
            .filter(item => item.title !== "" && item.content !== "" && item.type !== "" && item.image_url !== "")
            .map(({ id, ...rest }) => rest);

        updateSetup(JSON.stringify(newList));
    }

    // 1002
    const getSetup = async () => {
        try {
            const res = await mainAxios.get('setup', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: false
                });
                if (res.response.data.agent_banners) {
                    var agentBannersList = JSON.parse(res.response.data.agent_banners);
                    for (let i = 0; i < agentBannersList.length; i++) {
                        agentBannersList[i].id = i + 1;
                    }
                    if (agentBannersList.length !== 0) {
                        setAgentBanners(agentBannersList);
                    }
                }
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    // 3002
    const updateSetup = async (newAgentBanners) => {
        try {
            const res = await mainAxios.put('setup', {
                agent_banners: newAgentBanners,
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
                navigate("/setup/agent_banners");
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    function setTitle(id, title) {
        var newList = agentBanners;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].title = title;
            }
        }
        setAgentBanners([...newList]);
    }

    function setContent(id, content) {
        var newList = agentBanners;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].content = content;
            }
        }
        setAgentBanners([...newList]);
    }

    function setType(id, type) {
        var newList = agentBanners;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].type = type;
            }
        }
        setAgentBanners([...newList]);
    }

    function setEvent(id, event) {
        var newList = agentBanners;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].event = event;
            }
        }
        setAgentBanners([...newList]);
    }

    function setImage(id, image_url) {
        var newList = agentBanners;
        for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === id) {
                newList[i].image_url = image_url;
            }
        }
        setAgentBanners([...newList]);
    }

    function addAgentBanners() {
        var currentIndex = 0;
        for (let i = 0; i < agentBanners.length; i++) {
            if (agentBanners[i].id > currentIndex) {
                currentIndex = agentBanners[i].id;
            }
        }
        var newList = agentBanners.concat({ id: currentIndex + 1, title: "", content: "", type: AgentBannerTypeOption().length > 0 ? AgentBannerTypeOption()[0].value : '', event: AgentEventOption().length > 0 ? AgentEventOption()[0].value : '', image_url: "" });
        setAgentBanners([...newList]);
    }

    function removeAgentBanners(id) {
        if (agentBanners.length === 1) {
            agentBanners[0].title = "";
            agentBanners[0].content = "";
            agentBanners[0].type = AgentBannerTypeOption().length > 0 ? AgentBannerTypeOption()[0].value : '';
            agentBanners[0].event = AgentEventOption().length > 0 ? AgentEventOption()[0].value : '';
            agentBanners[0].image_url = "";
            setAgentBanners([...agentBanners]);
        } else {
            setAgentBanners((current) => current.filter((element) => {
                return element.id !== id;
            }));
        }
    }

    function onSidebarClick() { }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="agent_banners" onSidebarClick={onSidebarClick} navChild={{ setup: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="edit-agent-banners">
                        <form onSubmit={handleSave}>
                            <div className="header">
                                <div>
                                    <p>Edit agent banners</p>
                                    <p>Agent Banners &gt; Edit agent banners</p>
                                </div>
                                <button type='submit'>
                                    <span><TickIcon /></span>
                                    <span>Save</span>
                                </button>
                            </div>
                            <div className='content'>
                                <div className='details'>
                                    <div className='details-form'>
                                        <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                            <SortableContext items={agentBanners} strategy={verticalListSortingStrategy}>
                                                {agentBanners.map((agentBanner, index) => (
                                                    <AgentBannerItem key={index} index={index} id={agentBanner.id} title={agentBanner.title} content={agentBanner.content} type={agentBanner.type} event={agentBanner.event} image={agentBanner.image_url} setTitle={setTitle} setContent={setContent} setType={setType} setEvent={setEvent} setImage={setImage} addAgentBanners={addAgentBanners} removeAgentBanners={removeAgentBanners} setResponseData={setResponseData} />
                                                ))}
                                            </SortableContext>
                                        </DndContext>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {createDialog()}
                    </div >
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(EditAgentBanners);