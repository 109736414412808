import React, { useEffect, useState } from 'react'
import './addEditService.scss'
import { ReactComponent as TickIcon } from '../../assets/ic_tick.svg';
import { ReactComponent as PlaceHolderIcon } from '../../assets/img_company_placeholder.svg';
import { ReactComponent as AddIcon } from '../../assets/ic_add.svg';
import { ReactComponent as CloseIcon } from '../../assets/ic_close_outline.svg'
import { ReactComponent as RadioCheckedIcon } from '../../assets/ic_radio_checked.svg'
import { ReactComponent as RadioUncheckedIcon } from '../../assets/ic_radio_unchecked.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import checkRole from '../../checkRole';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { mainAxios } from '../../axiosConfig';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import { blockInvalidChar, createEditorStateFromHtml, getAvatar, getName, isValidValue, replaceEmptyPTagWithBrTa } from '../../Utils';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

const AddEditService = (params) => {
    const navigate = useNavigate();
    const locationState = useLocation();
    const [dialog, setDialog] = useState({
        showDialog: false,
        message: ""
    });
    const [responseData, setResponseData] = useState({
        loading: false,
        imageData: ""
    });
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [rate, setRate] = useState('');
    const [designations, setDesignations] = useState('');
    const [designationsInputText, setDesignationsInputText] = useState([])
    const [upcoming, setUpcoming] = useState(false);
    const [info, setInfo] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (params.action === "edit") {
            setResponseData({
                ...responseData,
                loading: true
            });
            getServiceDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSave = (event) => {
        event.preventDefault();

        if (title.length > 0 && subtitle.length > 0 && rate.length !== "" && responseData.imageData !== "") {
            setResponseData({
                ...responseData,
                loading: true,
            });
            if (params.action === "add") {
                submitNewService();
            } else {
                editService();
            }
        } else {
            setDialog({ showDialog: true, message: "Please complete all required fields." });
        }
    }

    // 1021
    const getServiceDetails = async () => {
        try {
            const res = await mainAxios.get('service/' + locationState.state.id, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: false,
                    imageData: res.response.data.service.image_url
                });
                setTitle(res.response.data.service.title);
                setSubtitle(res.response.data.service.subtitle);
                setRate(res.response.data.service.rate_per_hour);
                let newList = [];
                let designationsArray = res.response.data.service.designations.split(',');
                for (let i = 0; i < designationsArray.length; i++) {
                    if (i === 0) {
                        setDesignations(designationsArray[i]);
                    } else {
                        newList.push({ id: i, value: designationsArray[i] });
                    }
                }
                setDesignationsInputText(newList);
                setUpcoming(res.response.data.service.is_upcoming);
                setInfo(createEditorStateFromHtml(res.response.data.service.info_html ?? ''));
            } else {
                setResponseData({
                    ...responseData,
                    loading: false
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false
            });
        }
    }

    // 2021
    const submitNewService = async () => {
        try {
            var designationsData = "";
            if (designations.length > 0) {
                designationsData = designations;
            }

            for (let i = 0; i < designationsInputText.length; i++) {
                if (designationsInputText[i].value.length > 0) {
                    if (designationsData === "") {
                        designationsData = designationsInputText[i].value;
                    } else {
                        designationsData = designationsData + "," + designationsInputText[i].value;
                    }
                }
            }

            var info_html = "";
            const infoHtml = convertToHTML({
                styleToHTML: (style) => {
                    if (style === "BOLD") {
                        return <b />;
                    } else if (style === "ITALIC") {
                        return <i />;
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === 'LINK') {
                        const { url } = entity.data;
                        return <a href={url}>{originalText}</a>
                    }
                    return originalText;
                }
            })(info.getCurrentContent());

            if (infoHtml && infoHtml.trim() !== '<p></p>') {
                info_html = replaceEmptyPTagWithBrTa(infoHtml);
            }

            const res = await mainAxios.post('service', {
                image_url: responseData.imageData,
                title: title,
                subtitle: subtitle,
                rate_per_hour: rate,
                designations: designationsData,
                info_html: info_html,
                is_upcoming: upcoming
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
                navigate("/services");
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    // 2022
    const uploadServiceImage = async (files) => {
        setResponseData({
            ...responseData,
            loading: true,
        });
        try {
            const formData = new FormData();
            formData.append("file", files);
            const res = await mainAxios.post('service_image', formData, {
                headers: { "Content-Type": 'multipart/form-data' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                    imageData: res.response.data.image_url
                });
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                    imageData: ""
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
                imageData: ""
            });
        }
    }

    // 3021
    const editService = async () => {
        try {
            var designationsData = "";
            if (designations.length > 0) {
                designationsData = designations;
            }

            for (let i = 0; i < designationsInputText.length; i++) {
                if (designationsInputText[i].value.length > 0) {
                    if (designationsData === "") {
                        designationsData = designationsInputText[i].value;
                    } else {
                        designationsData = designationsData + "," + designationsInputText[i].value;
                    }
                }
            }

            var info_html = "";
            const infoHtml = convertToHTML({
                styleToHTML: (style) => {
                    if (style === "BOLD") {
                        return <b />;
                    } else if (style === "ITALIC") {
                        return <i />;
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === 'LINK') {
                        const { url } = entity.data;
                        return <a href={url}>{originalText}</a>
                    }
                    return originalText;
                }
            })(info.getCurrentContent());

            if (infoHtml && infoHtml.trim() !== '<p></p>') {
                info_html = replaceEmptyPTagWithBrTa(infoHtml);
            }

            const res = await mainAxios.put('service/' + locationState.state.id, {
                image_url: responseData.imageData,
                title: title,
                subtitle: subtitle,
                rate_per_hour: rate,
                designations: designationsData,
                info_html: info_html,
                is_upcoming: upcoming
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
                navigate("/service/details?id=" + locationState.state.id);
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
    }

    function createDialog() {
        return dialog.showDialog ?
            (
                <ConfirmDialog
                    iconTitle={''}
                    title={''}
                    subtitle={dialog.message}
                    subTitleStyle={{ fontSize: 16 }}
                    backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                    button1Style={{ visibility: "hidden" }}
                    button2Icon={''}
                    button2Title={'OK'}
                    button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                    button2Callback={(id) => setDialog({ showDialog: false })}
                />
            )
            : null
    }

    const addDiv = () => {
        var currentIndex = 0;
        for (let i = 0; i < designationsInputText.length; i++) {
            if (designationsInputText[i].id > currentIndex) {
                currentIndex = designationsInputText[i].id;
            }
        }
        setDesignationsInputText(current => [...current, { id: currentIndex + 1, value: "" }]);
    }

    const removeDiv = (inputID) => {
        setDesignationsInputText((current) => current.filter((element) => {
            return element.id !== inputID;
        }));
    }

    const updateDiv = (inputID, inputValue) => {
        const newList = [...designationsInputText];
        const designationsItem = newList.find(element => element.id === inputID);
        designationsItem.value = inputValue;
        setDesignationsInputText(newList);
    }

    function onSidebarClick() { }

    if (params.action === "add") {
        return (
            <div className='app-main'>
                <div className={`${params.navClose ? 'navClose' : ""}`}>
                    <Sidebar page="services" onSidebarClick={onSidebarClick} />
                </div>
                <div className='app-main-content'>
                    <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                    <div className='app-content'>
                        <div className="add-edit-service">
                            <form onSubmit={handleSave}>
                                <div className="header">
                                    <div>
                                        <p>Add new service</p>
                                        <p>Services &gt; Add new service </p>
                                    </div>
                                    {/* <button onClick={() => navigate('/companies', { replace: true })}> */}
                                    <button type='submit'>
                                        <span><TickIcon /></span>
                                        <span>Save</span>
                                    </button>
                                </div>
                                <div className='content'>
                                    <div className='details'>
                                        <div className='details-form'>
                                            <div className='details-div'>
                                                <label className='required'>Title:</label>
                                                <div className='details-div-layout'>
                                                    <input className='details-div-layout-input' type='text' onChange={(e) => setTitle(e.target.value)} value={title} required />
                                                </div>
                                            </div>
                                            <div className='details-div form-textarea'>
                                                <label className='required'>Subtitle:</label>
                                                <div className='details-div-layout'>
                                                    <textarea className='details-div-layout-textarea' rows={4} onChange={(e) => setSubtitle(e.target.value)} value={subtitle} required />
                                                </div>
                                            </div>
                                            <div className='details-div'>
                                                <label className='required'>Rate Per Hour:</label>
                                                <div className='details-div-layout'>
                                                    <input className='details-div-layout-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setRate(e.target.value)} value={rate} min={0} required />
                                                </div>
                                            </div>
                                            <div className='details-div multi-div'>
                                                <label>Designations:</label>
                                                <div className='details-div-layout'>
                                                    <div className='details-div-icon'>
                                                        <input className='details-div-layout-input' type='text' onChange={(e) => setDesignations(e.target.value)} value={designations} />
                                                        <AddIcon className='details-add-icon' onClick={() => addDiv()} />
                                                    </div>
                                                    {designationsInputText.map((item) => {
                                                        return (
                                                            <div key={item.id} className='details-div-icon mutli-input-layout'>
                                                                <input className='details-div-layout-input' type='text' onChange={(e) => updateDiv(item.id, e.target.value)} value={item.value} />
                                                                <CloseIcon className='details-remove-icon' onClick={() => removeDiv(item.id)} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className='details-div form-textarea details-div-html'>
                                                <label>Info:</label>
                                                <div className='details-div-layout'>
                                                    {/* dropdown option not working in strict mode */}
                                                    <Editor
                                                        toolbar={{
                                                            options: ['inline', 'list', 'link', 'emoji', 'history'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            }
                                                        }}
                                                        editorState={info}
                                                        toolbarClassName='notification-toolbar'
                                                        wrapperClassName="notification-wrapper"
                                                        editorClassName="notification-editor"
                                                        onEditorStateChange={(e) => setInfo(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-div'>
                                                <label className='required'>Upcoming:</label>
                                                <div className='details-div-layout'>
                                                    <div className='details-div-icon'>
                                                        <div className='details-div-radio' onClick={() => setUpcoming(true)}>
                                                            {upcoming ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                                                            <label className='details-div-radio-label'>Yes</label>
                                                        </div>
                                                        <div className='details-div-radio2' onClick={() => setUpcoming(false)}>
                                                            {upcoming ? <RadioUncheckedIcon /> : <RadioCheckedIcon />}
                                                            <label className='details-div-radio-label'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='details-image'>
                                        <div className='logo'>
                                            <label className='required'>Image:</label>
                                            <div className='upload'>
                                                {isValidValue(responseData.imageData) ? <img className='upload-logo' src={responseData.imageData} alt="" /> : <PlaceHolderIcon />}
                                                <div className='upload-image' onClick={() => document.querySelector(".input-logo").click()}>
                                                    <label>Upload Image</label>
                                                    <input className='input-logo' type='file' accept='image/png, image/jpeg, image/jpg' hidden onChange={({ target: { files } }) => {
                                                        if (files && files[0]) {
                                                            uploadServiceImage(files[0]);
                                                        }
                                                    }} />
                                                </div>
                                                {isValidValue(responseData.imageData) ? <button className='remove-image' onClick={() => {
                                                    document.querySelector(".input-logo").value = "";
                                                    setResponseData({ ...responseData, imageData: "" });
                                                }}>Remove</button> : ""}
                                                <p>File size: maximum 5 MB, size: (140 x 90 px)</p>
                                                <p>File extension: .JPG, .JPEG, .PNG</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {createDialog()}
                        </div >
                    </div>
                    {responseData.loading ? <LoadingSpinner /> : undefined}
                </div>
            </div>
        )
    } else {
        return (
            <div className='app-main'>
                <div className={`${params.navClose ? 'navClose' : ""}`}>
                    <Sidebar page="services" onSidebarClick={onSidebarClick} />
                </div>
                <div className='app-main-content'>
                    <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                    <div className='app-content'>
                        <div className="add-edit-service">
                            <form onSubmit={handleSave}>
                                <div className="header">
                                    <div>
                                        <p>Edit service details</p>
                                        <p>Services &gt; Service details &gt; Edit service details </p>
                                    </div>
                                    {/* <button onClick={() => navigate('/companies', { replace: true })}> */}
                                    <button type='submit'>
                                        <span><TickIcon /></span>
                                        <span>Save</span>
                                    </button>
                                </div>
                                <div className='content'>
                                    <div className='details'>
                                        <div className='details-form'>
                                            <div className='details-div'>
                                                <label className='required'>Title:</label>
                                                <div className='details-div-layout'>
                                                    <input className='details-div-layout-input' type='text' onChange={(e) => setTitle(e.target.value)} value={title} required />
                                                </div>
                                            </div>
                                            <div className='details-div form-textarea'>
                                                <label className='required'>Subtitle:</label>
                                                <div className='details-div-layout'>
                                                    <textarea className='details-div-layout-textarea' rows={4} onChange={(e) => setSubtitle(e.target.value)} value={subtitle} required />
                                                </div>
                                            </div>
                                            <div className='details-div'>
                                                <label className='required'>Rate Per Hour:</label>
                                                <div className='details-div-layout'>
                                                    <input className='details-div-layout-input' type='number' onKeyDown={blockInvalidChar} onChange={(e) => setRate(e.target.value)} value={rate} min={0} required />
                                                </div>
                                            </div>
                                            <div className='details-div multi-div'>
                                                <label>Designations:</label>
                                                <div className='details-div-layout'>
                                                    <div className='details-div-icon'>
                                                        <input className='details-div-layout-input' type='text' onChange={(e) => setDesignations(e.target.value)} value={designations} />
                                                        <AddIcon className='details-add-icon' onClick={() => addDiv()} />
                                                    </div>
                                                    {designationsInputText.map((item) => {
                                                        return (
                                                            <div key={item.id} className='details-div-icon mutli-input-layout'>
                                                                <input className='details-div-layout-input' type='text' onChange={(e) => updateDiv(item.id, e.target.value)} value={item.value} />
                                                                <CloseIcon className='details-remove-icon' onClick={() => removeDiv(item.id)} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className='details-div form-textarea details-div-html'>
                                                <label>Info:</label>
                                                <div className='details-div-layout'>
                                                    {/* dropdown option not working in strict mode */}
                                                    <Editor
                                                        toolbar={{
                                                            options: ['inline', 'list', 'link', 'emoji', 'history'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            }
                                                        }}
                                                        editorState={info}
                                                        toolbarClassName='notification-toolbar'
                                                        wrapperClassName="notification-wrapper"
                                                        editorClassName="notification-editor"
                                                        onEditorStateChange={(e) => setInfo(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='details-div'>
                                                <label className='required'>Upcoming:</label>
                                                <div className='details-div-layout'>
                                                    <div className='details-div-icon'>
                                                        <div className='details-div-radio' onClick={() => setUpcoming(true)}>
                                                            {upcoming ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                                                            <label className='details-div-radio-label'>Yes</label>
                                                        </div>
                                                        <div className='details-div-radio2' onClick={() => setUpcoming(false)}>
                                                            {upcoming ? <RadioUncheckedIcon /> : <RadioCheckedIcon />}
                                                            <label className='details-div-radio-label'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='details-image'>
                                        <div className='logo'>
                                            <label className='required'>Image:</label>
                                            <div className='upload'>
                                                {isValidValue(responseData.imageData) ? <img className='upload-logo' src={responseData.imageData} alt="" /> : <PlaceHolderIcon />}
                                                <div className='upload-image' onClick={() => document.querySelector(".input-logo").click()}>
                                                    <label>Upload Image</label>
                                                    <input className='input-logo' type='file' accept='image/png, image/jpeg, image/jpg' hidden onChange={({ target: { files } }) => {
                                                        if (files && files[0]) {
                                                            uploadServiceImage(files[0]);
                                                        }
                                                    }} />
                                                </div>
                                                {isValidValue(responseData.imageData) ? <button className='remove-image' onClick={() => {
                                                    document.querySelector(".input-logo").value = "";
                                                    setResponseData({ ...responseData, imageData: "" });
                                                }}>Remove</button> : ""}
                                                <p>File size: maximum 5 MB, size: (140 x 90 px)</p>
                                                <p>File extension: .JPG, .JPEG, .PNG</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {createDialog()}
                        </div >
                    </div>
                    {responseData.loading ? <LoadingSpinner /> : undefined}
                </div>
            </div>
        )
    }
}

export default checkRole(AddEditService);