import React from 'react';
import './confirmDialog.scss';
import { ReactComponent as TrashTitleIcon } from '../../assets/ic_trash_large.svg'
import { ReactComponent as TrashButtonIcon } from '../../assets/ic_trash_outline.svg'
import { ReactComponent as CloseIcon } from '../../assets/ic_close_outline.svg'

const ConfirmDialog = ({ id, iconTitle, title, subtitle, titleStyle, subTitleStyle, button1Title, button1Icon, button1Callback, button1Style, button2Title, button2Icon, button2Callback, button2Style, backgroundButtonCallback, array, isPayment }) => {
    return (
        <div className='confirm-dialog'>
            <button className='backgroundButton' onClick={() => backgroundButtonCallback === undefined ? console.log('backgroundButtonCallback') : backgroundButtonCallback(id)} />
            <div className='container'>
                <div className='titleContainer'>
                    {iconTitle ?? <TrashTitleIcon />}
                    <div className={`titleWrapper ${isPayment ? 'details-mutli-line' : ''}`}>
                        <p className='title' style={titleStyle}>{title}</p>
                        {
                            isPayment ?
                                array.map((item) => {
                                    return (
                                        <div className='details-layout'>
                                            <div className='details-layout-item'>
                                                <p>{item.key}:</p>
                                            </div>
                                            <div className='details-layout-item'>
                                                <p>{item.value}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                : <p className='subtitle' style={subTitleStyle}>{subtitle}</p>
                        }
                    </div>
                </div>
                <div className='buttonContainer'>
                    <button style={button1Style} onClick={() => button1Callback === undefined ? console.log('button1Callback') : button1Callback(id)}>
                        {button1Icon ?? <CloseIcon />}
                        {button1Title ?? 'Cancel'}
                    </button>
                    <button style={button2Style} onClick={() => button2Callback === undefined ? console.log('button2Callback') : button2Callback(id)}>
                        {button2Icon ?? <TrashButtonIcon />}
                        {button2Title ?? 'Delete'}
                    </button>
                </div>
            </div >
        </div >
    )
}

export function ShowConfirmDialog() {
    return (
        <ConfirmDialog />
    )
}

export default ConfirmDialog;