import "./admins.scss";
import { React, useEffect, useState } from 'react';
import checkRole from '../../checkRole';
import { useNavigate, useLocation } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import CustomTable from "../../components/CustomTable/CustomTable";
import { mainAxios } from "../../axiosConfig";
import { getAvatar, getName, renameKey, sortTable, filterTable, isValidValue } from "../../Utils";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from '../../components/SearchBar/SearchBar';

const Admins = (params) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialog, setDialog] = useState({
    showDialog: false,
    isDisable: false,
    selectedRow: {},
    message: ""
  });
  const [responseData, setResponseData] = useState({
    loading: true,
    data: []
  });

  const [sorting, setSorting] = useState({
    column: "",
    assending: true
  });
  const [filterValue, setFilterValue] = useState({
    searchValue: "",
    filterValue: []
  });
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    if (!responseData.loading) {
      setResponseData({
        loading: true,
        data: []
      });
    }
    getUserListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const adminColumns = [
    { label: "Name", accessor: "name", isSort: true, width: '35%', type: "string", isFilter: true },
    { label: "Email", accessor: "login_id", isSort: true, width: '35%', type: "string", isFilter: true },
    { label: "Last Login", accessor: "last_login", isSort: true, width: '30%', type: "date", sortingKey: 'last_login_at', isFilter: true },
  ]

  // 1010
  const getUserListAPI = async () => {
    try {
      const res = await mainAxios.get('admins', {
        headers: { "Content-Type": 'application/x-www-form-urlencoded' }
      });
      if (res.isSuccess) {
        var userList = res.response.data.admins;
        userList.forEach(obj => renameKey(obj, "admin_id", "id"))
        const currentDate = new Date();
        for (let i = 0; i < userList.length; i++) {
          let lastLoginAt = userList[i].last_login_at;
          if (isValidValue(lastLoginAt)) {
            const lastLoginAtDate = new Date(lastLoginAt);
            const timeDifference = Math.abs(lastLoginAtDate - currentDate);
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const years = Math.round((timeDifference / 1000 / (60 * 60 * 24)) / 365.25);

            const lastLogin = seconds > 59 ? minutes > 59 ? hours > 23 ? days > 364 ? years + (years > 1 ? " year ago" : " years ago") : days + (days > 1 ? " days ago" : " days ago") : hours + (hours > 1 ? " hours ago" : " hour ago") : minutes + (minutes > 1 ? " minutes ago" : " minute ago") : seconds + (seconds > 1 ? " seconds ago" : " second ago")
            userList[i].last_login = lastLogin;
          }

          let deactivateAt = userList[i].deactivate_at;
          // if (deactivateAt != null) {
          //   const deactivateAtDate = new Date(deactivateAt);
          //   userList[i].isDisable = deactivateAtDate <= currentDate;
          // } else {
          //   userList[i].isDisable = false
          // }
          userList[i].isDisable = deactivateAt;
          var mobileNo = "";
          if (isValidValue(userList[i].country_code)) {
            mobileNo = userList[i].country_code + " ";
          }

          if (isValidValue(userList[i].phone_number)) {
            mobileNo = mobileNo + userList[i].phone_number;
          }

          userList[i].mobile_no = mobileNo;
          userList[i].name = userList[i].username;
        }
        setResponseData({
          loading: false,
          data: userList
        });

        filter(filterValue, userList);
      } else {
        setResponseData({
          loading: false,
          data: {}
        });

        filter(filterValue, []);
      }
    } catch (error) {
      setResponseData({
        loading: false,
        data: {}
      });

      filter(filterValue, []);
    }
  }

  function onClickRow(id) {
    users(id, 'view');
  }

  function users(id, action) {
    for (var data of responseData.data) {
      if (data.id === id) {
        navigate(`/admins/details?id=` + id);
        break;
      }
    }
  }

  function onClickStatus(id) {
    for (var data of responseData.data) {
      if (data.id === id) {
        setDialog({
          showDialog: true,
          isDisable: true,
          selectedRow: data
        })
      }
    }
  }

  function createDialog() {
    return dialog.showDialog ?
      (
        <ConfirmDialog
          iconTitle={''}
          title={''}
          subtitle={dialog.message}
          subTitleStyle={{ fontSize: 16 }}
          backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
          button1Style={{ visibility: "hidden" }}
          button2Icon={''}
          button2Title={'OK'}
          button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
          button2Callback={(id) => setDialog({ showDialog: false })}
        />
      )
      : null
  }

  function onSidebarClick() {
    setResponseData({
      loading: true,
      data: {}
    });
    getUserListAPI();
  }

  function onClickColumn(column, arr) {
    var columns = adminColumns;
    var data = sortTable(column, columns, arr ?? filterList);
    column.data = data;
    setSorting(column);
  }

  function filter(filter, arr) {
    var list = filterTable(filter, arr ?? responseData.data);
    setFilterList(list);
    onClickColumn(sorting, list)
  }

  function handleSearchChange(value) {
    setFilterValue(value)
    filter(value);
  }

  return (
    <div className='app-main'>
      <div className={`${params.navClose ? 'navClose' : ""} `}>
        <Sidebar page={'admins'} onSidebarClick={onSidebarClick} navChild={{ accounts: true }} />
      </div>
      <div className='app-main-content'>
        <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
        <div className='app-content'>
          <div className="admins">
            <div className="header">
              <p>{`LIST OF ADMINS`}</p>
              <SearchBar
                handleSearchChange={(value) => handleSearchChange(value)}
                value={filterValue}
                filterList={adminColumns}
              />

            </div>
            <div className="table-container">
              <CustomTable
                columns={adminColumns}
                tableData={sorting.data ?? filterList}
                numPerPage={10}
                isEdit={false}
                isDelete={false}
                isMore={false}
                isDisable={true}
                isDisableOnClickRow={true}
                onClickRow={(id) => onClickRow(id)}
                onClickStatus={(id) => onClickStatus(id)}
                sorting={sorting}
                onClickColumn={(column) => onClickColumn(column)}
              />
            </div>
            {createDialog()}
          </div>
        </div>
        {responseData.loading ? <LoadingSpinner /> : undefined}
      </div>
    </div>
  )
}

export default checkRole(Admins);