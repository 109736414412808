import Select, { components } from "react-select"

export const CustomSelect = ({ list, placeholder = 'Select...', required = false, isIcon = false, isMulti = false, value, onChangeValue, hasAll = false, valueForAll = '' }) => {
    const getDefaultArray = () => {
        var defaultArray = [];
        for (let i = 0; i < list.length; i++) {
            if (isMulti) {
                var hasItem = false;
                for (let a = 0; a < value.length; a++) {
                    if (value[a].value === list[i].value) {
                        hasItem = true;
                    }
                }
                if (hasItem) {
                    defaultArray.push(list[i]);
                }
            } else {
                if (list[i].value === value.value) {
                    defaultArray.push(list[i]);
                }
            }
        }
        return defaultArray;
    }

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.data.icon ? <img
                    src={require('../../assets/' + props.data.icon + '.png')}
                    style={{ width: 30, marginRight: '10px' }}
                    alt={props.data.label}
                /> : undefined}
                {props.data.label}
            </div>
        </Option>
    );

    return (
        <Select
            options={list}
            isClearable={false}
            isSearchable={false}
            isLoading={false}
            isDisabled={false}
            placeholder={placeholder}
            value={value != null ? getDefaultArray() : undefined}
            required={required}
            components={!isIcon ? {
                IndicatorSeparator: () => null,
            } : {
                IndicatorSeparator: () => null,
                Option: IconOption
            }}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 10,
                    fontSize: 13,
                    boxShadow: 'none',
                    border: 0,
                    minHeight: '32px',
                    height: isMulti ? undefined : '36px'
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    minHeight: '36px',
                    height: isMulti ? undefined : '36px',
                    padding: '2px 10px'
                }),
                input: (provided, state) => ({
                    ...provided,
                    margin: '0px'
                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                    minHeight: '36px',
                    height: isMulti ? undefined : '36px'
                }),
                option: (provided, state) => ({
                    ...provided,
                    fontSize: 13
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                    color: '#656565'
                }),
                multiValueLabel: (provided, state) => ({
                    ...provided,
                    color: '#676767'
                }),
                multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: '#A5A5A5',
                    ':hover': {
                        backgroundColor: '#FC7921',
                        color: 'white'
                    }
                })
            }}
            isMulti={isMulti}
            onChange={opt => {
                if (hasAll && opt.length > 0 && isMulti) {
                    var containAll = false;
                    var allIsSelectedBefore = false;
                    var containAllIndex = -1;

                    for (let index = 0; index < opt.length; index++) {
                        const element = opt[index];
                        if (element.value === valueForAll) {
                            containAll = true;
                            containAllIndex = index;
                        }
                    }
                    value.forEach(element => {
                        if (element.value === valueForAll) {
                            allIsSelectedBefore = true;
                        }
                    });

                    if (containAll) {
                        if (allIsSelectedBefore) {
                            if (opt.length !== list.length) {
                                opt.splice(containAllIndex, 1)
                                onChangeValue(opt);
                            } else {
                                onChangeValue(list)
                            }
                        } else {
                            onChangeValue(list)
                        }
                    } else {
                        if (opt.length === list.length - 1) {
                            if (allIsSelectedBefore) {
                                onChangeValue([])
                            } else {
                                onChangeValue(list)
                            }
                        } else {
                            onChangeValue(opt)
                        }
                    }
                } else {
                    onChangeValue(opt)
                }
            }} />
    )
}