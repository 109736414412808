import React, { useEffect, useState } from 'react'
import './addEditNotificationTemplate.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import checkRole from '../../../checkRole';
import { ReactComponent as TickIcon } from '../../../assets/ic_tick.svg';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Navbar from '../../../components/Navbar/Navbar';
import { createEditorStateFromHtml, getAvatar, getName, IconOption, isValidValue, NotificationTypeOption, replaceEmptyPTagWithBrTa, RoleTypeOption } from '../../../Utils';
import { CustomSelect } from '../../../components/SelectOption/CustomSelect';
import { ReactComponent as PlaceHolderIcon } from '../../../assets/img_company_placeholder.svg';
import { mainAxios } from '../../../axiosConfig';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { ReactComponent as RadioCheckedIcon } from '../../../assets/ic_radio_checked.svg'
import { ReactComponent as RadioUncheckedIcon } from '../../../assets/ic_radio_unchecked.svg'
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const AddEditNotificationTemplate = (params) => {
    const navigate = useNavigate();
    const locationState = useLocation();
    var resData = (locationState.state ?? {}).data ?? {};
    var filter = JSON.parse(resData.filter ?? "{}") ?? {};

    const [dialog, setDialog] = useState({
        showDialog: false,
        message: ""
    });

    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState({
        totalUser: 0,
        notification: 0,
        pushNotification: 0
    })

    const [title, setTitle] = useState(createEditorStateFromHtml(resData.title_html ?? ''));
    const [message, setMessage] = useState(createEditorStateFromHtml(resData.message_html ?? ''));
    const [contentDescription, setContentDescription] = useState(createEditorStateFromHtml(resData.content_description_html ?? ''));

    const [titleString, setTitleString] = useState(resData.title_string ?? "");
    const [contentTitle, setContentTitle] = useState(resData.content_title ?? "");

    const [notificationType, setNotificationType] = useState(NotificationTypeOption().length > 0 ? NotificationTypeOption()[0] : {});
    const [notificationTypes, setNotificationTypes] = useState([]);
    const [icon, setIcon] = useState({});
    const [icons, setIcons] = useState([]);
    const [role, setRole] = useState(RoleTypeOption().length > 0 ? RoleTypeOption()[0] : {});
    const [roles, setRoles] = useState([]);

    const [ageStart, setAgeStart] = useState("");
    const [ageEnd, setAgeEnd] = useState("");
    const [ageOptions, setAgeOptions] = useState([]);
    const [isPushNotificationAlert, setIsPushNotificationAlert] = useState(resData.to_push ?? false);

    const [logoData, setLogoData] = useState(resData.image_url ?? "");
    const emailType = "e";

    useEffect(() => {
        if (isValidValue(resData.icon_name)) {
            setIcon({
                label: resData.icon_name,
                value: resData.icon_name,
                icon: resData.icon_name
            });
        }
        setIcons(IconOption());
        updateRoleTypeList();
        updateNotificationTypeList();
        updateAgeOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (true) {
            const id = setTimeout(() => {
                filterNotification_API();
                clearInterval(id);
            }, 3000)

            return () => clearInterval(id);
        }
    }, [role, ageStart, ageEnd, isPushNotificationAlert])

    // 2055
    async function filterNotification_API() {
        try {
            const res = await mainAxios.post('filter_notification', {
                role: role.value,
                filter: JSON.stringify({
                    from_age: ageStart.value === "" ? null : parseInt(ageStart.value),
                    to_age: ageEnd.value === "" ? null : parseInt(ageEnd.value),
                })
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setStatistics({
                    totalUser: res.response.data.total,
                    notification: res.response.data.filtered_total,
                    pushNotification: res.response.data.filtered_push_total
                })
            }
        } catch (error) {
            setLoading(false);
        }
    }

    function updateRoleTypeList() {
        var nlist = RoleTypeOption();
        setRoles(nlist);
        nlist.forEach(obj => {
            let role = resData.role;
            if (obj.value === role) {
                setRole(obj);
            }
        });
    };

    function updateNotificationTypeList() {
        var nlist = NotificationTypeOption();
        setNotificationTypes(nlist);
        nlist.forEach(obj => {
            let type = resData.type;
            if (obj.value === type) {
                setNotificationType(obj);
            }
        });
    }

    function updateAgeOptions() {
        var newList = [];
        var startAge = undefined;
        var endAge = undefined;
        for (let index = 0; index <= 100; index++) {
            let str = index.toString();
            var arr = { value: str, label: str };
            newList.push(arr);

            if (filter.from_age === index) {
                startAge = arr;
            }

            if (parseInt(filter.to_age) === index) {
                endAge = arr;
            }
        }
        newList.unshift({ value: "", label: "No age restriction" });
        setAgeOptions(newList);
        setAgeStart(startAge ?? newList[0]);
        setAgeEnd(endAge ?? newList[0]);
    }

    const handleSave = (event) => {
        event.preventDefault();

        let isEdit = params.action === "edit";

        if (notificationType.value === emailType) {
            const contentDescriptionHtml = convertToHTML({
                styleToHTML: (style) => {
                    if (style === 'BOLD') {
                        return <b />;
                    } else if (style === 'ITALIC') {
                        return <i />;
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === 'LINK') {
                        const { url } = entity.data;
                        return <a href={url}>{originalText}</a>
                    }
                    return originalText;
                }
            })(contentDescription.getCurrentContent());

            if (contentDescriptionHtml && contentDescriptionHtml.trim() !== "<p></p>" && notificationType.value !== undefined && titleString.length > 0 && contentTitle.length > 0) {
                let payload = getEmailSavePayloadData(replaceEmptyPTagWithBrTa(contentDescriptionHtml));
                if (isEdit) {
                    updateNotification(payload);
                } else {
                    addNotification(payload);
                }
            } else {
                setDialog({ showDialog: true, message: "Please complete all required fields." });
            }
        } else {
            const titleHtml = convertToHTML({
                styleToHTML: (style) => {
                    if (style === "BOLD") {
                        return <b />;
                    } else if (style === "ITALIC") {
                        return <i />;
                    }
                }
            })(title.getCurrentContent());
            const messageHtml = convertToHTML({
                styleToHTML: (style) => {
                    if (style === 'BOLD') {
                        return <b />;
                    } else if (style === 'ITALIC') {
                        return <i />;
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === 'LINK') {
                        const { url } = entity.data;
                        return <a href={url}>{originalText}</a>
                    }
                    return originalText;
                }
            })(message.getCurrentContent());

            if (titleHtml && titleHtml.trim() !== '<p></p>' && messageHtml && messageHtml.trim() !== "<p></p>" && notificationType.value !== undefined && icon.value !== undefined) {
                let payload = getSavePayloadData(replaceEmptyPTagWithBrTa(titleHtml), replaceEmptyPTagWithBrTa(messageHtml));
                if (isEdit) {
                    updateNotification(payload);
                } else {
                    addNotification(payload);
                }
            } else {
                setDialog({ showDialog: true, message: "Please complete all required fields." });
            }
        }
    }

    function getSavePayloadData(titleHtml, messageHtml) {
        return {
            data: JSON.stringify({
                title_html: titleHtml,
                message_html: messageHtml,
                image_url: logoData,
                icon_name: icon.value
            }),
            type: notificationType.value,
            role: role.value,
            filter: JSON.stringify({
                from_age: ageStart.value === "" ? null : parseInt(ageStart.value),
                to_age: ageEnd.value === "" ? null : parseInt(ageEnd.value),
            }),
            to_push: isPushNotificationAlert
        }
    }

    function getEmailSavePayloadData(contentDescriptionHtml) {
        return {
            data: JSON.stringify({
                title: titleString,
                content_title: contentTitle,
                content_description_html: contentDescriptionHtml,
            }),
            type: notificationType.value,
            role: role.value,
            filter: JSON.stringify({
                from_age: ageStart.value === "" ? null : parseInt(ageStart.value),
                to_age: ageEnd.value === "" ? null : parseInt(ageEnd.value),
            }),
            to_push: false
        }
    }

    // 2053
    async function addNotification(payload) {
        setLoading(true);
        try {
            const res = await mainAxios.post('notification_template', payload, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            setLoading(false);
            if (res.isSuccess) {
                navigate("/notifications/template");
            }
        } catch (error) {
            setLoading(false);
        }
    }

    // 3053
    async function updateNotification(payload) {
        try {
            const res = await mainAxios.put('notification_template/' + resData.id, payload, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            setLoading(false);
            if (res.isSuccess) {
                navigate("/notifications/template");
            }
        } catch (error) {
            setLoading(false);
        }
    }

    function createDialog() {
        return dialog.showDialog ? (
            <ConfirmDialog
                iconTitle={''}
                title={''}
                subtitle={dialog.message}
                subTitleStyle={{ fontSize: 16 }}
                backgroundButtonCallback={(id) => setDialog({ showDialog: false })}
                button1Style={{ visibility: "hidden" }}
                button2Icon={''}
                button2Title={'OK'}
                button2Style={{ backgroundColor: '#FC7921', color: 'white' }}
                button2Callback={(id) => setDialog({ showDialog: false })}
            />
        )
            : null
    }

    function onSidebarClick() { }

    // 2056
    const uploadBanner = async (files) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", files);
            const res = await mainAxios.post('notification_image', formData, {
                headers: { "Content-Type": 'multipart/form-data' }
            });
            setLoading(false);
            if (res.isSuccess) {
                setLogoData(res.response.data.image_url);
            } else {
                setLogoData("");
            }
        } catch (error) {
            setLoading(false);
            setLogoData("");
        }
    }

    function getContentDescription() {
        const contentDescriptionHtml = convertToHTML({
            styleToHTML: (style) => {
                if (style === 'BOLD') {
                    return <b />;
                } else if (style === 'ITALIC') {
                    return <i />;
                }
            },
            entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                    const { url } = entity.data;
                    return <a href={url}>{originalText}</a>
                }
                return originalText;
            }
        })(contentDescription.getCurrentContent());
        if (contentDescriptionHtml && contentDescriptionHtml.trim() !== "<p></p>") {
            return parse(DOMPurify.sanitize(replaceEmptyPTagWithBrTa(contentDescriptionHtml)));
        } else {
            return "";
        }
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page={"notification_template"} onSidebarClick={onSidebarClick} navChild={{ manage_notifications: true }} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="add-edit-notification-template">
                        <form onSubmit={handleSave}>
                            <div className="header">
                                <div>
                                    {params.action === "edit" ? <p>Edit notification template</p> : <p>Add new notification template</p>}
                                    {params.action === "edit" ? <p>Notification Templates &gt; Notification detail &gt; Edit notification template</p> : <p>Notification Templates &gt; Add new notification template</p>}
                                </div>
                                <button className='edit-button'>
                                    <span><TickIcon /></span>
                                    <span>Save</span>
                                </button>
                            </div>
                            <div className='content'>
                                <div className='details-container'>
                                    <div className='details'>
                                        <p className='details-title'>Notifications Detail</p>

                                        <div className='details-form'>
                                            <div className='details-div dropdown-div'>
                                                <label className='required'>Notification Type:</label>
                                                <div className='details-div-layout details-div-multi-layout'>
                                                    <CustomSelect list={notificationTypes} placeholder='Select Notification Type' required={true} isMulti={false} value={notificationType} onChangeValue={(e) => setNotificationType(e)} />
                                                </div>
                                            </div>
                                            <div className='details-div dropdown-div'>
                                                <label className='required'>Role:</label>
                                                <div className='details-div-layout details-div-multi-layout'>
                                                    <CustomSelect list={roles} placeholder='Select Role' required={true} isMulti={false} value={role} onChangeValue={(e) => setRole(e)} />
                                                </div>
                                            </div>
                                            {
                                                notificationType.value === emailType ?
                                                    <div className='details-div'>
                                                        <label className='required'>Title:</label>
                                                        <div className='details-div-layout'>
                                                            <input className='details-div-layout-input' type='text' onChange={(e) => setTitleString(e.target.value)} value={titleString} required />
                                                        </div>
                                                    </div> :
                                                    <div className='details-div form-textarea'>
                                                        <label className='required'>Title:</label>
                                                        <div className='details-div-layout'>
                                                            {/* dropdown option not working in strict mode */}
                                                            <Editor
                                                                toolbar={{
                                                                    options: ['inline', 'emoji', 'history'],
                                                                    inline: {
                                                                        options: ['bold', 'italic', 'underline'],
                                                                    }
                                                                }}
                                                                editorState={title}
                                                                toolbarClassName='notification-toolbar'
                                                                wrapperClassName="notification-wrapper"
                                                                editorClassName="notification-editor"
                                                                onEditorStateChange={(e) => setTitle(e)}
                                                            />
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                notificationType.value === emailType ?
                                                    <div className='details-div'>
                                                        <label className='required'>Content Title:</label>
                                                        <div className='details-div-layout'>
                                                            <input className='details-div-layout-input' type='text' onChange={(e) => setContentTitle(e.target.value)} value={contentTitle} required />
                                                        </div>
                                                    </div> : undefined
                                            }
                                            {
                                                notificationType.value === emailType ?
                                                    <div className='details-div form-textarea'>
                                                        <label className='required'>Content Description:</label>
                                                        <div className='details-div-layout'>
                                                            {/* dropdown option not working in strict mode */}
                                                            <Editor
                                                                toolbar={{
                                                                    options: ['inline', 'link', 'emoji', 'history'],
                                                                    inline: {
                                                                        options: ['bold', 'italic', 'underline'],
                                                                    }
                                                                }}
                                                                editorState={contentDescription}
                                                                toolbarClassName='notification-toolbar'
                                                                wrapperClassName="notification-wrapper"
                                                                editorClassName="notification-editor"
                                                                onEditorStateChange={(e) => setContentDescription(e)}
                                                            />
                                                        </div>
                                                    </div> :
                                                    <div className='details-div form-textarea'>
                                                        <label className='required'>Message:</label>
                                                        <div className='details-div-layout'>
                                                            {/* dropdown option not working in strict mode */}
                                                            <Editor
                                                                toolbar={{
                                                                    options: ['inline', 'link', 'emoji', 'history'],
                                                                    inline: {
                                                                        options: ['bold', 'italic', 'underline'],
                                                                    }
                                                                }}
                                                                editorState={message}
                                                                toolbarClassName='notification-toolbar'
                                                                wrapperClassName="notification-wrapper"
                                                                editorClassName="notification-editor"
                                                                onEditorStateChange={(e) => setMessage(e)}
                                                            />
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                notificationType.value === emailType ?
                                                    <div className='details-div form-textarea'>
                                                        <label>Content Preview:</label>
                                                        <div className='details-div-layout'>
                                                            <div className='details-preview'>
                                                                <b>{isValidValue(contentTitle) ? contentTitle : '$content_title'}</b>
                                                                <br /> <br />
                                                                Hi $first_name $last_name,
                                                                <br /> <br />
                                                                {isValidValue(getContentDescription()) ? getContentDescription() : '$content_description_html'}
                                                                {isValidValue(getContentDescription()) ? undefined : <br />}
                                                                <br />
                                                                Regards, <br />
                                                                The RiceGrains
                                                            </div>
                                                        </div>
                                                    </div> : undefined
                                            }
                                            {
                                                notificationType.value === emailType ? undefined :
                                                    <div className='details-div dropdown-div'>
                                                        <label className='required'>Icon :</label>
                                                        <div className='details-div-layout details-div-multi-layout'>
                                                            <CustomSelect list={icons} placeholder='Select Icon' required={true} isIcon={true} isMulti={false} value={icon} onChangeValue={(e) => setIcon(e)} />
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                notificationType.value === emailType ? undefined :
                                                    <div className='details-image'>
                                                        <div className='logo'>
                                                            <label className='logo-label'>Photo:</label>
                                                            <div className='upload'>
                                                                {isValidValue(logoData) ? <img className='upload-logo' src={logoData} alt="Photo" /> : <PlaceHolderIcon />}
                                                                <div className='upload-image' onClick={() => document.querySelector(".input-logo").click()}>
                                                                    <label>Upload Image</label>
                                                                    <input className='input-logo' type='file' accept='image/png, image/jpeg' hidden onChange={({ target: { files } }) => {
                                                                        if (files && files[0]) {
                                                                            uploadBanner(files[0]);
                                                                        }
                                                                    }} />
                                                                </div>
                                                                {isValidValue(logoData) ? <button className='remove-image' onClick={() => {
                                                                    document.querySelector(".input-logo").value = "";
                                                                    setLogoData("");
                                                                }}>Remove</button> : ""}
                                                                <p>File size: maximum 5 MB, size: (160 x 160 px)</p>
                                                                <p>File extension: .JPEG, .PNG</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                notificationType.value === emailType ? undefined :
                                                    <div className='details-div'>
                                                        <label className='required'>Send Push Notifications:</label>
                                                        <div className='details-div-layout'>
                                                            <div className='details-div-icon'>
                                                                <div className='details-div-radio' onClick={() => setIsPushNotificationAlert(true)}>
                                                                    {isPushNotificationAlert ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                                                                    <label className='details-div-radio-label'>Yes</label>
                                                                </div>
                                                                <div className='details-div-radio2' onClick={() => setIsPushNotificationAlert(false)}>
                                                                    {isPushNotificationAlert ? <RadioUncheckedIcon /> : <RadioCheckedIcon />}
                                                                    <label className='details-div-radio-label'>No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            <p className='details-title'>Notifications Filter {`(${statistics.notification} / ${statistics.totalUser} users)`}</p>
                                            <div className='details-div dropdown-div'>
                                                <label>Age Range :</label>
                                                <div className='range-div'>
                                                    <div className='details-div-layout details-div-multi-layout'>
                                                        <CustomSelect list={ageOptions} placeholder='Select Start Age' isMulti={false} value={ageStart}
                                                            onChangeValue={(e) => {
                                                                setAgeStart(e);
                                                                if (e.value !== '') {
                                                                    if (parseInt(e.value) > parseInt(ageEnd.value)) {
                                                                        setAgeEnd(e)
                                                                    }
                                                                }
                                                            }} />
                                                    </div>
                                                    <label style={{ textAlign: 'center' }}>  to  </label>
                                                    <div className='details-div-layout details-div-multi-layout'>
                                                        <CustomSelect list={ageOptions} placeholder='Select End Age' isMulti={false} value={ageEnd}
                                                            onChangeValue={(e) => {
                                                                setAgeEnd(e);
                                                                if (e.value !== '') {
                                                                    if (parseInt(e.value) < parseInt(ageStart.value)) {
                                                                        setAgeStart(e)
                                                                    }
                                                                }
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {createDialog()}
                    </div>
                </div>
                {loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(AddEditNotificationTemplate);