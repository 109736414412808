import "./sessions.scss";
import { React, useEffect, useState } from 'react';
import CustomTable from "../../components/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { getAvatar, getName, renameKey, sortTable, filterTable, dateToString, isValidValue, setupName, getDateTimeWithGMT } from '../../Utils.jsx'
import checkRole from '../../checkRole';
import { mainAxios } from "../../axiosConfig";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from '../../components/SearchBar/SearchBar';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import CustomTableDialog from "../../components/CustomTableDialog/CustomTableDialog.jsx";

const Sessions = (params) => {
    const navigate = useNavigate();
    const [tableDialog, setTableDialog] = useState({
        session_id: "",
        showDialog: false,
        isRedButton: false
    });
    const [responseData, setResponseData] = useState({
        loading: true,
        data: []
    });

    const [sorting, setSorting] = useState({
        column: "",
        assending: true
    });
    const [filterValue, setFilterValue] = useState({
        searchValue: "",
        filterValue: []
    });
    const [filterList, setFilterList] = useState([]);
    const [calendar, setCalendar] = useState(new Date());
    const [selectedId, setSelectedId] = useState("");

    useEffect(() => {
        getSessionListAPI(calendar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        { label: "#", accessor: "code", isSort: true, width: '16%', type: "string", isFilter: true, isAbleClick: true },
        { label: "Service", accessor: "service", isSort: true, width: '15%', type: "string", isFilter: true },
        { label: "Booking Status", accessor: "booking_status", isSort: true, width: '25%', type: "string", isFilter: true },
        { label: "Session Status", accessor: "session_status", isSort: true, width: '26%', type: "string", isFilter: true },
        { label: "Agent", accessor: "agent_details", isSort: true, width: '18%', type: "string", isFilter: true, isCustomButton: true, buttonText: "Assign", redButtonText: "Re-assign", isSkipDefault: true, justifyContent: 'center' }
    ]

    const agentColumns = [
        { label: "Name", accessor: "name", isSort: true, width: '35%', type: "string", isFilter: true, isOpenAgentDetails: true },
        { label: "Email", accessor: "login_id", isSort: true, width: '35%', type: "string", isFilter: true },
        { label: "Mobile No.", accessor: "mobile_no", isSort: true, width: '30%', type: "string", isFilter: true }
    ]

    // 1040
    const getSessionListAPI = async (date) => {
        try {
            const res = await mainAxios.get('sessions', {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' },
                params: { "date": dateToString(date, "YYYY-MM-DD") }
            });
            if (res.isSuccess) {
                var sessionList = res.response.data.sessions;
                sessionList.forEach(obj => renameKey(obj, "session_id", "id"))
                for (let i = 0; i < sessionList.length; i++) {
                    var isPay = false;
                    var isCancel = false;
                    if (sessionList[i].booking) {
                        sessionList[i].service = sessionList[i].booking.title;
                        var code = "Booking: " + sessionList[i].booking.booking_code + "\n" + "Session: " + sessionList[i].session_code;
                        if (sessionList[i].booking.pay_at != null) {
                            isPay = true;
                        }
                        if (sessionList[i].booking.cancel_at != null) {
                            isCancel = true;
                        }
                        sessionList[i].booking_status =
                            "Book: " + (!isValidValue(sessionList[i].booking.book_at) ? "-" : getDateTimeWithGMT(sessionList[i].booking.book_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                            "Pay: " + (!isValidValue(sessionList[i].booking.pay_at) ? "-" : getDateTimeWithGMT(sessionList[i].booking.pay_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                            "Cancel: " + (!isValidValue(sessionList[i].booking.cancel_at) ? "-" : getDateTimeWithGMT(sessionList[i].booking.cancel_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                            "Refund: " + (!isValidValue(sessionList[i].booking.refund_at) ? "-" : getDateTimeWithGMT(sessionList[i].booking.refund_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma"));
                    }
                    if (sessionList[i].agent) {
                        var phoneNumber = "";
                        if (isValidValue(sessionList[i].agent.country_code)) {
                            phoneNumber += sessionList[i].agent.country_code + " ";
                        }
                        if (isValidValue(sessionList[i].agent.phone_number)) {
                            phoneNumber += sessionList[i].agent.phone_number;
                        }
                        sessionList[i].agent_details = (sessionList[i].agent.login_id ?? "-") + "\n" + (setupName(sessionList[i].agent.first_name, sessionList[i].agent.last_name) ?? "-") + "\n" + (phoneNumber === "" ? "-" : phoneNumber);
                    }
                    sessionList[i].code = code;
                    sessionList[i].session_status =
                        "Start: " + (!isValidValue(sessionList[i].start_at) ? "-" : getDateTimeWithGMT(sessionList[i].start_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "End: " + (!isValidValue(sessionList[i].end_at) ? "-" : getDateTimeWithGMT(sessionList[i].end_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Assign: " + (!isValidValue(sessionList[i].assign_at) ? "-" : getDateTimeWithGMT(sessionList[i].assign_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Accept: " + (!isValidValue(sessionList[i].accept_at) ? "-" : getDateTimeWithGMT(sessionList[i].accept_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Decline: " + (!isValidValue(sessionList[i].decline_at) ? "-" : getDateTimeWithGMT(sessionList[i].decline_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Complete: " + (!isValidValue(sessionList[i].complete_at) ? "-" : getDateTimeWithGMT(sessionList[i].complete_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Review: " + (!isValidValue(sessionList[i].review_at) ? "-" : getDateTimeWithGMT(sessionList[i].review_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma")) + "\n" +
                        "Pay: " + (!isValidValue(sessionList[i].pay_at) ? "-" : getDateTimeWithGMT(sessionList[i].pay_at, sessionList[i].booking.address_timezone_offset, "D MMM YY, h:mma"));
                    sessionList[i].isDisableCustom = !isPay || isCancel || sessionList[i].complete_at != null;
                    sessionList[i].isRedButton = sessionList[i].decline_at != null;
                }
                setResponseData({
                    loading: false,
                    data: sessionList
                });

                filter(filterValue, sessionList);
            } else {
                setResponseData({
                    loading: false,
                    data: {}
                });
                filter(filterValue, []);
            }
        } catch (error) {
            setResponseData({
                loading: false,
                data: {}
            });
            filter(filterValue, []);
        }
    }

    // 3042
    const assignAgentAPI = async (id) => {
        try {
            const res = await mainAxios.put('session_assign/' + id, {
                agent_id: selectedId
            }, {
                headers: { "Content-Type": 'application/x-www-form-urlencoded' }
            });
            if (res.isSuccess) {
                setResponseData({
                    loading: true,
                    data: {}
                });
                getSessionListAPI(calendar);
            } else {
                setResponseData({
                    ...responseData,
                    loading: false,
                });
            }
        } catch (error) {
            setResponseData({
                ...responseData,
                loading: false,
            });
        }
        setSelectedId("");
    }

    function onClickRow(id) {
        sessions(id, 'view');
    }

    function sessions(id, action) {
        for (var data of responseData.data) {
            if (data.id === id) {
                navigate("/session/details?id=" + id);
                break;
            }
        }
    }

    function createTableDialog() {
        return tableDialog.showDialog ?
            (
                <CustomTableDialog
                    columns={agentColumns}
                    numPerPage={3}
                    onClickTableRow={(id) => onClickTableRow(id)}
                    backgroundButtonCallback={(id) => handleCancelCallback()}
                    button1Callback={(id) => handleCancelCallback()}
                    button2Title={tableDialog.isRedButton ? 'Re-assign' : 'Assign'}
                    button2Style={selectedId === "" ? { backgroundColor: '#dbdbdb', color: '#656565' } : tableDialog.isRedButton ? { backgroundColor: '#FF0000', color: 'white' } : { backgroundColor: '#FC7921', color: 'white' }}
                    isDisableButton2={selectedId === ""}
                    button2Callback={(id) => handleButton2Callback()}
                />
            ) : null
    }

    function handleCancelCallback() {
        setSelectedId("");
        setTableDialog({ showDialog: false, session_id: "", isRedButton: false })
    }

    function handleButton2Callback() {
        setResponseData({
            ...responseData,
            loading: true,
        });
        assignAgentAPI(tableDialog.session_id)
        setTableDialog({ showDialog: false, session_id: "", isRedButton: false })
    }

    function onClickTableRow(id) {
        setSelectedId(id);
    }

    function onSidebarClick() {
        setResponseData({
            loading: true,
            data: {}
        });
        getSessionListAPI(calendar);
    }

    function onClickColumn(column, arr) {
        var data = sortTable(column, columns, arr ?? filterList);
        column.data = data;
        setSorting(column);
    }

    function onCustomButton(id, isRedButton) {
        setTableDialog({ showDialog: true, session_id: id, isRedButton: isRedButton });
    }

    function filter(filter, arr) {
        var list = filterTable(filter, arr ?? responseData.data);
        setFilterList(list);
        onClickColumn(sorting, list)
    }

    function handleSearchChange(value) {
        setFilterValue(value)
        filter(value);
    }

    function handleCalendarChange(value) {
        setCalendar(value)
        setResponseData({
            loading: true,
            data: {}
        });
        getSessionListAPI(value)
    }

    return (
        <div className='app-main'>
            <div className={`${params.navClose ? 'navClose' : ""}`}>
                <Sidebar page="sessions" onSidebarClick={onSidebarClick} />
            </div>
            <div className='app-main-content'>
                <Navbar navClose={params.navClose} setNavClose={params.setNavClose} name={getName()} avatar={getAvatar()} />
                <div className='app-content'>
                    <div className="sessions">
                        <div className="header">
                            <p>BOOKED SESSIONS</p>
                        </div>
                        <div className="sessions-calendar">
                            <Calendar onChange={(value) => handleCalendarChange(value)} value={calendar} />
                        </div>
                        <div className="search-bar">
                            <SearchBar
                                handleSearchChange={(value) => handleSearchChange(value)}
                                value={filterValue}
                                filterList={columns}
                            />
                        </div>
                        <div className="table-container">
                            <CustomTable
                                columns={columns}
                                tableData={sorting.data ?? filterList}
                                isDelete={false}
                                isEdit={false}
                                isDisableOnClickRow={true}
                                numPerPage={10}
                                onClickRow={(id) => onClickRow(id)}
                                onClickViewMore={(id) => onClickRow(id)}
                                onCustomButton={(id, isRedButton) => onCustomButton(id, isRedButton)}
                                sorting={sorting}
                                onClickColumn={(column) => onClickColumn(column)}
                            />
                        </div>
                        {createTableDialog()}
                    </div>
                </div>
                {responseData.loading ? <LoadingSpinner /> : undefined}
            </div>
        </div>
    )
}

export default checkRole(Sessions);