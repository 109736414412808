import React from 'react'
import { ReactComponent as EditIcon } from '../../assets/ic_edit.svg';
import { ReactComponent as TrashIcon } from '../../assets/ic_trash.svg';
import { ReactComponent as MoreIcon } from '../../assets/ic_more.svg';
import { ReactComponent as DisableIcon } from '../../assets/ic_disable.svg';
import { ReactComponent as EnableIcon } from '../../assets/ic_enable.svg';
import { ReactComponent as SentIcon } from '../../assets/ic_sent.svg';
import { ApplicationStatusDropDownOption } from '../../Utils'
import { CustomSelect } from '../../components/SelectOption/CustomSelect';
import { Link } from 'react-router-dom';

const CustomTableBody = ({ columns, tableData, isEdit, isDelete, isMore, isDisable, isSent, isDeleteDisabled, isDisableOnClickRow, onClickRow, onClickEdit, onClickDelete, onClickViewMore, onClickStatus, onClickSent, onCustomButton, onChangeDrowDownValue }) => {
    const handleIconClick = (action, id, isRedButton) => {
        if (action === "edit") {
            onClickEdit(id);
        } else if (action === "trash") {
            onClickDelete(id);
        } else if (action === "disable" || action === "enable") {
            onClickStatus(id);
        } else if (action === "sent") {
            onClickSent(id);
        } else if (action === "custom") {
            onCustomButton(id, isRedButton);
        } else {
            onClickViewMore(id);
        }
    }

    function applicationStatusColor(status) {
        let list = ApplicationStatusDropDownOption();
        var color = "black";

        list.forEach(element => {
            if (element.value === status) {
                color = element.color;
            }
        });

        return color;
    }

    return (
        <tbody>
            {tableData.map((data) => {
                let disabled = data.disabled ?? {};
                return (
                    <tr key={data.id} onClick={() => isDisableOnClickRow ? null : onClickRow(data.id)} className={`${data.isDisable || data.isSelected ? 'tr-disable' : ''} ${isDisableOnClickRow ? 'tr-disable-pointer' : ''}`} >
                        {columns.map(({ label, accessor, styleColumn, isColorField, isDropDown, ddList, isAbleClick, isCustomButton, buttonText, redButtonText, isSkipDefault, isOpenAgentDetails }) => {
                            const tData = data[accessor] != null && data[accessor] !== "" ? data[accessor] : isSkipDefault ? "" : "-";
                            let disabledField = disabled[accessor] ?? false;
                            return accessor === "action" ? (
                                <td key={accessor} onClick={e => e.stopPropagation()}>
                                    <div className='custom-table-body-icon'>
                                        {isEdit ? <button onClick={() => handleIconClick("edit", data.id, false)}>
                                            <EditIcon />
                                        </button> : undefined}
                                        {isDelete ? <button onClick={() => handleIconClick("trash", data.id, false)}>
                                            <TrashIcon />
                                        </button> : undefined}
                                        {isMore ? <button onClick={() => handleIconClick("more", data.id, false)}>
                                            <MoreIcon />
                                        </button> : undefined}
                                        {isDisable ? data.isDisable ? <button onClick={() => handleIconClick("enable", data.id, false)}>
                                            <EnableIcon />
                                        </button> : <button onClick={() => handleIconClick("disable", data.id, false)}>
                                            <DisableIcon />
                                        </button> : undefined}
                                        {isDeleteDisabled ? !data.isDisable ? <button onClick={() => handleIconClick("trash", data.id, false)}>
                                            <TrashIcon />
                                        </button> : <button onClick={() => handleIconClick("disable", data.id, false)}>
                                            <DisableIcon />
                                        </button> : undefined}
                                        {isSent ? <button onClick={() => handleIconClick("sent", data.id, false)}>
                                            <SentIcon />
                                        </button> : undefined}
                                    </div>
                                </td>
                            ) : (isDropDown ?
                                (
                                    disabledField ?
                                        <td key={accessor} /> :
                                        (
                                            <td key={accessor} onClick={e => e.stopPropagation()}>
                                                <div className='details-div dropdown-div'>
                                                    <div className='details-div-layout details-div-multi-layout'>
                                                        <CustomSelect list={ddList} placeholder={`Select ${label}`} required={true} isMulti={false} value={{ label: `${tData}`, value: `${tData}` }} onChangeValue={(e) => onChangeDrowDownValue(e, data.id, accessor)} />
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                )
                                :
                                (
                                    <td
                                        key={accessor}
                                    >
                                        {accessor === 'application_status' ?
                                            <div
                                                className={`custom-table-body active`}
                                                style={{ color: applicationStatusColor(tData) }}
                                            >{tData}
                                            </div>
                                            :

                                            <div
                                                className={`custom-table-body ${isColorField ? tData.includes("Active") ? 'active' : 'inactive' : ''} ${isAbleClick ? 'td-clickable' : ''}`}
                                                onClick={() => isAbleClick ? onClickRow(data.id) : null}
                                                style={styleColumn}
                                            >{tData.toString().includes("<hr />") ? tData.split("<hr />").map((splitData, index) => (
                                                <div key={"data" + index}>
                                                    {splitData}
                                                    {(index < tData.split("<hr />").length - 1) ? <hr /> : undefined}
                                                </div>
                                            )) : isCustomButton ?
                                                <div className='custom-button-container'>
                                                    <button className={`${data.isDisableCustom ? 'custom-button-disable' : data.isRedButton ? 'custom-button-enable-red' : 'custom-button-enable'}`} disabled={data.isDisableCustom} onClick={() => handleIconClick("custom", data.id, data.isRedButton)}>
                                                        {data.isRedButton ? redButtonText : buttonText}
                                                    </button>
                                                    {tData}
                                                </div> : isOpenAgentDetails ? <Link className='table-link' to={{ pathname: "/agents/details", search: "?id=" + data.id }} rel="noreferrer" target='_blank'>{tData}</Link> : tData}
                                            </div>
                                        }
                                    </td>
                                )
                            )
                        })}
                    </tr>
                )
            })}
        </tbody>
    )
}

export default CustomTableBody